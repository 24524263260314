<template>
    <div class="home">
        <div class="home_top">
            <div class="nav">
                <div class="nav_total">
                    <div class="nav_left">
                        <div class="left_item" @click="jump">
                            <img src="../assets/logo.png" alt="">
                            <p>不将ai</p>
                        </div>

                        <div class="nav_item">
                            <div v-for="(item, index) in nav" :key="index"
                                :class="[cutton == index ? 'active' : 'nomal']" @click="change(index)">
                                <div>
                                    {{ item.text }}

                                </div>

                                <div class="line">

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="nav_right">
                        <div v-for="(item, index) in role" :key="index" class="roles" @click="jump">
                            {{ item.text }}
                        </div>

                        <!-- <div class="login" @click="jump">
                        登录/注册
                    </div> -->
                    </div>
                </div>

            </div>

            <div class="content">
                <p class="title">
                    不将Ai素材，不将AI更专业
                </p>

                <p class="sub_title">
                    不将ai，已有875934名供稿人加入，本周上架35433件素材
                </p>

                <div class="search">
                    <input type="text" placeholder="请输入关键词/编号找素材">
                    <img src="../assets/search.png" alt="" @click="jump">
                </div>
            </div>

            <div class="nav_bottom">
                <div v-for="(item, index) in content" :key="index" class="bottom_item">
                    <p>
                        {{ item.title }}
                    </p>

                    <p>
                        {{ item.sub_title }}
                    </p>
                </div>
            </div>
        </div>

        <div class="home_container">
            <div class="title">
                每一份，都别出心裁
            </div>

            <div class="sub_title">
                非凡的作品，皆来自供稿人杰出的创作灵感与极致追求
            </div>

            <div class="imgs">
                <div v-for="(item, index) in imges" :key="index">
                    <img :src="item.url" alt="" @click="jump">
                </div>
            </div>
        </div>

        <div class="home_container">
            <div class="title">
                发现更多素材
            </div>

            <div class="sub_titled">
                <p v-for="(item, index) in more" :key="index" :class="[cuttons == index ? 'acitves' : 'nomals']"
                    @click="jump">
                    {{ item.text }}
                </p>
                <!-- 非凡的作品，皆来自供稿人杰出的创作灵感与极致追求 -->
            </div>

            <div class="imged">
                <img src="../assets/banner4.png" alt="" class="img" @click="jump">
                <img src="../assets/banner6.png" alt="" class="img2" @click="jump">
                <img src="../assets/banner7.png" alt="" class="img3" @click="jump">
                <img src="../assets/banner5.png" alt="" class="img1" @click="jump">
                <img src="../assets/banner8.png" alt="" class="img4" @click="jump">
                <img src="../assets/banner9.png" alt="" class="img5" @click="jump">
            </div>

            <div class="btn" @click="jump">
                <p>
                    查看更多
                </p>
            </div>
        </div>

        <div class="banner">
            <img src="../assets/banner10.png" alt="">

            <div class="banner_item">
                <p class="title">让创意更有价值</p>

                <p class="sub_title">不将AI,每年帮助数万名创作人获得上亿收益，追求更自由的工作</p>

                <div class="btns">
                    <p class="open" @click="jump">在不将开店</p>
                    <p class="look" @click="jump">宣传片</p>
                </div>
            </div>
        </div>

        <div class="sponsor">
            <div class="sponsor_left">
                <div class="top">
                    <img src="../assets/logo.png" alt="">
                    不将ai
                </div>

                <div class="title">
                    让创意更有价值，让世界更生动
                </div>

                <div class="sub_title">
                    我们相信，通过平台的效率和体验优化，搭建良好创作环境，就能源源不断输出好的数字创意作品，让世界更生动！
                </div>

                <div class="imgs">
                    <img :src="item.url" alt="" v-for="(item, index) in imgess" :key="index">
                </div>
            </div>

            <div class="sponsor_right">
                <div class="item">
                    <p @click="jump">不将视频</p>
                    <p @click="jump">上传视频</p>
                    <p @click="jump">精品视频</p>
                    <p @click="jump">精选专辑</p>
                    <p @click="jump">免费素材</p>
                </div>

                <div class="item">
                    <p @click="jump">不将音乐</p>
                    <p @click="jump">热门音乐</p>
                    <p @click="jump">热门歌单</p>
                    <p @click="jump">立即入驻</p>
                    <p @click="jump"></p>
                </div>

                <div class="item">
                    <p @click="jump">不将片场</p>
                    <p @click="jump">产品介绍</p>
                    <p @click="jump">上传案例</p>
                    <p @click="jump">热门案例</p>
                    <p @click="jump">创作人</p>
                </div>

                <div class="item">
                    <p @click="jump">关于不将</p>
                    <p @click="jump">关于我们</p>
                    <p @click="jump">数据分析</p>
                    <p @click="jump">帮助中心</p>
                    <p @click="jump">权责声明</p>
                </div>
            </div>
        </div>

        <div class="bottoms" style="">

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nav: [
                {
                    text: '首页'
                },

                {
                    text: '交易大厅'
                },

                {
                    text: '找创作人'
                },

                {
                    text: '版权中心'
                },
            ],

            cutton: 0,

            role: [
                {
                    text: '邀新'
                },

                {
                    text: '充值'
                }
            ],

            content: [
                {
                    title: '精准搜索',
                    sub_title: '自研多模态搜索算法'
                },

                {
                    title: '正版授权',
                    sub_title: '每件素材50万保险金'
                },

                {
                    title: '海量更新',
                    sub_title: '汇集全球优质投稿人'
                },

                {
                    title: '安心售后',
                    sub_title: '质量问题直接退'
                },
            ],

            imges: [
                {
                    url: require('/src/assets/banner1.png')
                },

                {
                    url: require('/src/assets/banner2.png')
                },

                {
                    url: require('/src/assets/banner3.png')
                },
            ],

            more: [
                {
                    text: '全部'
                },
                {
                    text: '音频素材'
                },
                {
                    text: '视频素材'
                },
                {
                    text: '科技动效'
                },
                {
                    text: '建模渲染'
                },
            ],

            imgess: [
                {
                    url: require('/src/assets/webChat.png')
                },

                {
                    url: require('/src/assets/douyin.png')
                },

                {
                    url: require('/src/assets/redBook.png')
                },

                {
                    url: require('/src/assets/email.png')
                },
            ],

            cuttons: 0
        }
    },

    methods: {
        change(index) {
            this.cutton = index
            // window.location.href = 'https://juhaimall.com.cn/web/#/'

            if (window.innerWidth >= 1200) {
                console.log(11);
                window.location.href = 'https://juhaimall.com.cn/web/#/'
            } else if (window.innerWidth <= 768) {
                console.log(22);
                window.location.href = 'https://juhaimall.com.cn/h5/#/'
            }
        },

        jump() {
            // window.location.href = 'https://juhaimall.com.cn/web/#/'

            if (window.innerWidth >= 1200) {
                console.log(11);
                window.location.href = 'https://juhaimall.com.cn/web/#/'
            } else if (window.innerWidth <= 768) {
                console.log(22);
                window.location.href = 'https://juhaimall.com.cn/h5/#/'
            }
        }
    }
}
</script>

<style lang="scss">
@media screen and (min-width: 1200px) {
    .home {

        .home_top {
            width: 100%;
            height: 900px;
            background-image: url(../assets/background.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            position: relative;

            .nav {
                width: 100%;
                height: 100px;

                .nav_total {
                    // width: 1400px;
                    height: 100%;
                    // margin: 0 auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 50px;
                    box-sizing: border-box;
                }

                .nav_left {
                    height: 100%;
                    // width: 200px;
                    display: flex;
                    align-items: center;

                    .left_item {
                        display: flex;
                        align-items: center;

                        img {
                            width: 50px;
                            height: 50px;
                            margin-right: 17px;
                        }

                        p {
                            font-weight: 400;
                            font-size: 28px;
                            color: #FFFFFF;
                            font-family: Inter, Inter;
                        }
                    }

                    .nav_item {
                        display: flex;
                        align-items: center;
                        margin-left: 50px;

                        .nomal {
                            margin-right: 80px;
                            position: relative;
                            font-weight: 400;
                            font-size: 18px;
                            color: #FFFFFF;

                            .line {
                                width: 100%;
                                height: 3px;
                                // background-color: ;
                                position: absolute;
                                bottom: -10px;
                            }
                        }

                        .active {
                            margin-right: 80px;
                            position: relative;
                            font-weight: 400;
                            font-size: 18px;
                            color: #FFFFFF;

                            .line {
                                width: 100%;
                                height: 3px;
                                background-color: #fff;
                                position: absolute;
                                bottom: -10px;
                            }
                        }
                    }
                }

                .nav_right {
                    height: 100%;
                    // width: 200px;
                    // background-color: springgreen;
                    display: flex;
                    align-items: center;

                    .roles {
                        margin-right: 80px;
                        font-weight: 400;
                        font-size: 18px;
                        color: #FFFFFF;
                    }

                    .login {
                        width: 130px;
                        height: 40px;
                        background-color: #fff;
                        border-radius: 25px;
                        font-weight: 400;
                        font-size: 18px;
                        color: #000000;
                        line-height: 40px;
                        text-align: center;
                    }
                }
            }

            .content {
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);

                .title {
                    font-weight: 400;
                    font-size: 50px;
                    color: #FFFFFF;
                    margin-bottom: 45px;
                }

                .sub_title {
                    font-weight: 400;
                    font-size: 28px;
                    color: #FFFFFF;
                    margin-bottom: 45px;
                }

                .search {
                    width: 1200px;
                    height: 80px;
                    position: relative;

                    input {
                        width: 100%;
                        height: 100%;
                        border-radius: 50px;
                        padding-left: 50px;
                        box-sizing: border-box;
                        border: 1px solid #FFFFFF !important;
                        font-weight: 400;
                        font-size: 28px;
                        color: #333333;
                    }

                    img {
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        top: 50%;
                        right: 40px;
                        transform: translate(0, -50%);
                    }
                }
            }

            .nav_bottom {
                width: 100%;
                height: 150px;
                background-color: rgb(90, 90, 90, 0.8);
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: space-around;

                .bottom_item {
                    text-align: left;

                    p {
                        font-weight: 400;
                        font-size: 24px;
                        color: #FFFFFF;
                        margin-bottom: 20px;
                        margin-top: 20px;
                    }
                }
            }
        }

        .home_container {
            width: 100%;
            // height: 500px;
            // background-color: aqua;
            padding: 100px 80px 50px;
            box-sizing: border-box;

            .title {
                font-weight: 400;
                font-size: 50px;
                color: #000000;
                margin-bottom: 40px;
            }

            .sub_title {
                font-weight: 400;
                font-size: 36px;
                color: #000000;
                margin-bottom: 80px;
            }

            .sub_titled {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 60px;
                margin-top: 80px;

                .acitves {
                    padding: 10px 30px;
                    box-sizing: border-box;
                    background-color: #333333;
                    margin-right: 20px;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    border-radius: 8px;
                    // text-align: center;
                }

                .nomals {
                    padding: 10px 30px;
                    box-sizing: border-box;
                    background-color: #EDEDED;
                    margin-right: 20px;
                    font-weight: 400;
                    font-size: 18px;
                    color: #333333;
                    border-radius: 8px;
                }
            }

            .imgs {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px;
                box-sizing: border-box;
                // background-color: aqua;

                img {
                    width: 450px;
                    height: 280px;
                    // margin-left: 10px;
                }
            }

            .imged {
                width: 100%;
                height: 670px;
                // background-color: aqua;
                position: relative;
                padding: 30px;
                box-sizing: border-box;

                .img {
                    width: 500px;
                    height: 450px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .img1 {
                    width: 500px;
                    height: 200px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                .img2 {
                    width: 500px;
                    height: 180px;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%);
                }

                .img3 {
                    width: 500px;
                    height: 460px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%);
                }

                .img4 {
                    width: 500px;
                    height: 180px;
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                .img5 {
                    width: 500px;
                    height: 460px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }

            .btn {
                width: 100%;
                height: 60px;
                // text-align: center;
                // line-height: 75px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 80px;

                p {
                    background-color: #EDEDED;
                    width: 200px;
                    height: 100%;
                    text-align: center;
                    line-height: 60px;
                    font-weight: 400;
                    font-size: 20px;
                    color: #000000;
                    border-radius: 50px;
                }
            }
        }

        .banner {
            width: 100%;
            height: 600px;
            position: relative;

            .banner_item {
                // width: 500px;
                // height: 200px;
                // background-color: aqua;
                position: absolute;
                top: 50%;
                left: 100px;
                transform: translate(0, -50%);
                text-align: left;
                padding: 20px;
                box-sizing: border-box;
            }

            img {
                width: 100%;
                height: 100%;
            }

            .title {
                font-weight: 400;
                font-size: 60px;
                color: #FFFFFF;
                margin-bottom: 20px;
            }

            .sub_title {
                font-weight: 400;
                font-size: 36px;
                color: #FFFFFF;
                margin-bottom: 40px;
            }

            .btns {
                display: flex;
                align-items: center;

                .open {
                    margin-right: 30px;
                    background-color: rgba(0, 0, 0, 0.5);
                    font-weight: 400;
                    font-size: 28px;
                    color: #FFFFFF;
                    padding: 15px 50px;
                    box-sizing: border-box;
                    border-radius: 50px;
                }

                .look {
                    margin-left: 30px;
                    font-weight: 400;
                    font-size: 28px;
                    color: #FFFFFF;
                    background-color: rgba(90, 90, 90, 0.5);
                    padding: 15px 50px;
                    box-sizing: border-box;
                    border-radius: 50px;
                }
            }
        }

        .sponsor {
            width: 100%;
            height: 500px;
            // background-color: aqua;
            display: flex;
            align-items: center;

            .sponsor_left {
                width: 50%;
                height: 500px;
                // background-color: red;
                text-align: left;
                padding: 50px;
                box-sizing: border-box;

                .top {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 30px;
                    color: #000000;
                    margin-bottom: 40px;

                    img {
                        width: 50px;
                        height: 50px;
                        margin-right: 20px;
                    }
                }

                .title {
                    font-weight: 400;
                    font-size: 24px;
                    color: #000000;
                    margin-bottom: 40px;
                }

                .sub_title {
                    font-weight: 400;
                    font-size: 20px;
                    color: #000000;
                    margin-bottom: 100px;
                }

                .imgs {

                    img {
                        width: 50px;
                        height: 50px;
                        margin-right: 30px;
                    }
                }
            }

            .sponsor_right {
                width: 50%;
                height: 500px;
                // background-color: springgreen;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    p {
                        // width: 100px;
                        height: 40px;
                        font-weight: 400;
                        font-size: 28px;
                        color: #000000;
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                }
            }
        }

        .bottoms {
            width: 100%;
            height: 60px;
            background-color: #333333;
        }
    }
}

@media (max-width: 768px) {
    .home {
        width: 100%;

        .home_top {
            width: 100%;
            height: 250px;
            background-image: url(../assets/background.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            position: relative;

            .nav {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .nav_total {
                    width: 100%;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .nav_left {
                        display: flex;
                        align-items: center;

                        .left_item {
                            display: flex;
                            align-items: center;

                            img {
                                width: 10px;
                                height: 10px;
                                // margin-left: 20px;
                                margin-right: 10px;
                            }

                            p {
                                font-weight: 400;
                                font-size: 8px;
                                color: #FFFFFF;
                                // font-family: Inter, Inter;
                            }
                        }

                        .nav_item {
                            display: flex;
                            align-items: center;
                            margin-left: 10px;

                            .active {
                                margin-right: 10px;
                                position: relative;
                                font-weight: 400;
                                font-size: 6px;
                                color: #FFFFFF;

                                .line {
                                    width: 100%;
                                    height: 1px;
                                    background-color: #fff;
                                    position: absolute;
                                    bottom: -5px;
                                }
                            }

                            .nomal {
                                margin-right: 10px;
                                position: relative;
                                font-weight: 400;
                                font-size: 6px;
                                color: #FFFFFF;

                                .line {
                                    width: 100%;
                                    height: 1px;
                                    // background-color: ;
                                    position: absolute;
                                    bottom: -10px;
                                }
                            }
                        }
                    }

                    .nav_right {
                        height: 100%;
                        // width: 200px;
                        // background-color: springgreen;
                        display: flex;
                        align-items: center;

                        .roles {
                            margin-right: 10px;
                            font-weight: 400;
                            font-size: 6px;
                            color: #FFFFFF;
                        }

                        .login {
                            width: 130px;
                            height: 40px;
                            background-color: #fff;
                            border-radius: 25px;
                            font-weight: 400;
                            font-size: 18px;
                            color: #000000;
                            line-height: 40px;
                            text-align: center;
                        }
                    }
                }


            }

            .content {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .title {
                    font-weight: 400;
                    font-size: 10px;
                    color: #FFFFFF;
                    margin-bottom: 5px;
                }

                .sub_title {
                    font-weight: 400;
                    font-size: 10px;
                    color: #FFFFFF;
                    margin-bottom: 25px;
                }

                .search {
                    width: 100%;
                    height: 25px;
                    position: relative;

                    input {
                        width: 100%;
                        height: 100%;
                        border-radius: 50px;
                        padding-left: 10px;
                        box-sizing: border-box;
                        border: 1px solid #FFFFFF !important;
                        font-weight: 400;
                        font-size: 10px;
                        color: #333333;
                    }

                    img {
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translate(0, -50%);
                    }
                }

            }

            .nav_bottom {
                width: 100%;
                height: 40px;
                background-color: rgb(90, 90, 90, 0.8);
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: space-around;

                .bottom_item {
                    text-align: left;

                    p {
                        font-weight: 400;
                        font-size: 6px;
                        color: #FFFFFF;
                        margin-bottom: 5px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    .home_container {
        width: 100%;
        // height: 500px;
        // background-color: aqua;
        padding: 30px 40px 15px;
        box-sizing: border-box;

        .title {
            font-weight: 400;
            font-size: 12px;
            color: #000000;
            margin-bottom: 8px;
        }

        .sub_title {
            font-weight: 400;
            font-size: 8px;
            color: #000000;
            margin-bottom: 15px;
        }

        .sub_titled {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 18px;
            margin-top: 20px;

            .acitves {
                padding: 5px 12px;
                box-sizing: border-box;
                background-color: #333333;
                // margin-right: 5px;
                // font-weight: 400;
                font-size: 8px;
                color: #FFFFFF;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                // text-align: center;
            }

            .nomals {
                padding: 5px 12px;
                box-sizing: border-box;
                background-color: #EDEDED;
                // margin-right: 5px;
                font-weight: 400;
                font-size: 8px;
                color: #333333;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .imgs {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            box-sizing: border-box;
            // background-color: aqua;

            img {
                width: 100px;
                height: 50px;
                // margin-left: 10px;
            }
        }

        .imged {
            width: 100%;
            height: 140px;
            // background-color: aqua;
            position: relative;
            padding: 30px;
            box-sizing: border-box;

            .img {
                width: 100px;
                height: 90px;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 3px;
            }

            .img1 {
                width: 100px;
                height: 40px;
                position: absolute;
                bottom: 0;
                left: 0;
                border-radius: 3px;
            }

            .img2 {
                width: 100px;
                height: 45px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%);
            }

            .img3 {
                width: 100px;
                height: 85px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
            }

            .img4 {
                width: 100px;
                height: 45px;
                position: absolute;
                top: 0;
                right: 0;
            }

            .img5 {
                width: 100px;
                height: 85px;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        .btn {
            width: 100%;
            height: 30px;
            // text-align: center;
            // line-height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            p {
                background-color: #EDEDED;
                width: 100px;
                height: 100%;
                text-align: center;
                line-height: 30px;
                font-weight: 400;
                font-size: 8px;
                color: #000000;
                border-radius: 50px;
            }
        }
    }

    .banner {
        width: 100%;
        height: 200px;
        position: relative;
        padding-top: 30px;

        .banner_item {
            // width: 500px;
            // height: 200px;
            // background-color: aqua;
            position: absolute;
            top: 50%;
            left: 30px;
            transform: translate(0, -50%);
            text-align: left;
            // padding: 20px;
            box-sizing: border-box;
        }

        img {
            width: 100%;
            height: 100%;
        }

        .title {
            font-weight: 400;
            font-size: 18px;
            color: #FFFFFF;
            margin-bottom: 10px;
        }

        .sub_title {
            font-weight: 400;
            font-size: 10px;
            color: #FFFFFF;
            margin-bottom: 12px;
        }

        .btns {
            display: flex;
            align-items: center;

            .open {
                margin-right: 15px;
                background-color: rgba(0, 0, 0, 0.5);
                font-weight: 400;
                font-size: 10px;
                color: #FFFFFF;
                padding: 8px 20px;
                box-sizing: border-box;
                border-radius: 50px;
            }

            .look {
                margin-left: 15px;
                font-weight: 400;
                font-size: 10px;
                color: #FFFFFF;
                background-color: rgba(90, 90, 90, 0.5);
                padding: 8px 20px;
                box-sizing: border-box;
                border-radius: 50px;
            }
        }
    }

    .sponsor {
        width: 100%;
        // height: 300px;
        // background-color: aqua;
        display: flex;
        align-items: center;

        .sponsor_left {
            width: 50%;
            height: 100%;
            // background-color: red;
            text-align: left;
            padding: 20px;
            box-sizing: border-box;

            .top {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 10px;
                color: #000000;
                margin-bottom: 10px;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 20px;
                }
            }

            .title {
                font-weight: 400;
                font-size: 10px;
                color: #000000;
                margin-bottom: 10px;
            }

            .sub_title {
                font-weight: 400;
                font-size: 8px;
                color: #000000;
                margin-bottom: 20px;
            }

            .imgs {

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 15px;
                }
            }
        }

        .sponsor_right {
            width: 50%;
            height: 100%;
            // background-color: springgreen;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                    // width: 100px;
                    height: 20px;
                    font-weight: 400;
                    font-size: 8px;
                    color: #000000;
                    margin-bottom: 2px;
                    margin-top: 2px;
                }
            }
        }
    }

    .bottoms {
        width: 100%;
        height: 30px;
        background-color: #333333;
    }
}
</style>